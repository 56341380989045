import { LanguageKeys } from "@/locales/types";
import { defineStore } from "pinia";
import Postmate from "postmate";

interface UserPermissions {
  contentAiEnabled: boolean;
}

interface FeaturePermissions {
  contentAI: boolean;
}

export interface AppStore {
  locationId: string;
  userEmail: string;
  userId: string;
  token: string;
  appHandshake: Postmate.Model | undefined;
  companyId: string;
  timezone: string;
  appType: "STANDALONE" | "EMBED";
  userPermissions?: UserPermissions;
  featurePermissions?: FeaturePermissions;
  locale: LanguageKeys;
  rootUrl: string;
  homeId: string;
}

export const useAppStore = defineStore("app", {
  state: (): AppStore => ({
    locationId: (import.meta.env.VITE_LOCATION_ID as string) || "",
    userId: (import.meta.env.VITE_USER_ID as string) || "",
    userEmail: (import.meta.env.VITE_USER_EMAIL as string) || "",
    token: (import.meta.env.VITE_TOKEN_ID as string) || "",
    appType: import.meta.env.VITE_APP_TYPE || "STANDALONE",
    companyId: "",
    timezone: "",
    appHandshake: undefined,
    userPermissions: {
      contentAiEnabled: false,
    },
    featurePermissions: {
      contentAI: false,
    },
    locale: LanguageKeys.englishUS,
    rootUrl: "",
    homeId: "",
  }),
  actions: {
    initialise(payload: AppStore) {
      this.locationId = payload.locationId;
      this.userId = payload.userId;
      this.userEmail = payload.userEmail;
      this.token = payload.token;
      this.companyId = payload.companyId;
      this.timezone = payload.timezone;
      this.appType = payload.appType;
      this.userPermissions = payload.userPermissions;
      this.featurePermissions = payload.featurePermissions;
      this.locale = payload.locale;
      this.rootUrl = payload.rootUrl;
    },
    refreshToken(newToken: string) {
      this.token = newToken;
    },
    setAppHandshake(payload: any) {
      this.appHandshake = payload;
    },
    isfeatureActive() {
      const now = new Date();
      const levelupDate = new Date("2024-10-21T00:00:00-04:00");
      const excemptedAccounts = [
        "ktapXmiRvMM4pJHyN3b3",
        "YuTUZlUtrwBtvmgByZDW",
        "5DP4iH6HLkQsiKESj6rh", // Staging
      ];
      if (this.companyId) {
        if (now >= levelupDate || excemptedAccounts.indexOf(this.companyId) > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
});
